import React from "react"
import { graphql } from "gatsby"
import Meta from "../../components/layouts/meta"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Layout from "../../components/layouts/default"
import * as styles from "../../styles/page/second.module.scss"

// プロジェクト
import TopProject from "../../components/parts/top/project"

const ArticlePage = ({ data }) => {
  const projectData = data.project.edges

  const title = 'プロジェクト情報'
  
  return (
    <Layout>
      <Meta 
        title={title}
        path="/project/"
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        
        <div className={styles.projectList}>
          <div className={styles.projectListInner}>
            {projectData.map( (item, id) => {
              return <TopProject key={`top-project-${id}`} index={id} data={item.node} />
            })}
          </div>
        </div>

      </main>
    </Layout>
  )
}
export default ArticlePage

export const query = graphql`
  query ProjectListQuery {
    project: allProjectsJson {
      edges {
        node {
          slug
          title
          description
          mainImage
          relatedResource {
            link
            title
          }
        }
      }
    }
  }
`